var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("uploadProcessStep1.pageTitle"),
          items: _vm.items,
        },
      }),
      !_vm.isPageLoading
        ? _c("div", [
            _vm.isAllowedToImportData
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 text-left mb-5" },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("uploadProcessStep1.form.title")
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 text-right mb-5" },
                            [
                              _vm.isStep1Completed &&
                              _vm.isOriginalDatafileUploaded
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "warning" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickOnResetProcess()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep1.form.resetButton"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "mdi mdi-autorenew",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c("div", { staticClass: "col-md-3" }),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-5" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "datafileTitle" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep1.form.name.label"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          _c("span", [
                                            _vm.datafileTitle
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "badge position-absolute ml-2",
                                                    class: {
                                                      "bg-light":
                                                        _vm.datafileTitle
                                                          .length !== 100,
                                                      "bg-danger":
                                                        _vm.datafileTitle
                                                          .length === 100,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.datafileTitle
                                                            .length
                                                        ) +
                                                        " / 100 "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: _vm.datafileTitle,
                                            expression: "datafileTitle",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.datafileTitle.$error,
                                        },
                                        attrs: {
                                          id: "datafileTitle",
                                          type: "text",
                                          name: "datafileTitle",
                                          placeholder: _vm.$t(
                                            "uploadProcessStep1.form.name.placeholder"
                                          ),
                                        },
                                        domProps: { value: _vm.datafileTitle },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.datafileTitle =
                                              $event.target.value.trim()
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                      _vm.$v.datafileTitle.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm.$v.datafileTitle.$error &&
                                              !_vm.$v.datafileTitle.required
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep1.form.name.requiredErrorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$v.datafileTitle.$error &&
                                              !_vm.$v.datafileTitle.maxLength
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep1.form.name.maxLengthErrorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-5" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep1.form.fileSelector.label"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v("*")]
                                        ),
                                        _vm.originalDatafileuploadProcessing &&
                                        !_vm.fileUploadError
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass:
                                                  "bx bx-loader-circle bx-spin ml-2",
                                              }),
                                            ])
                                          : _vm._e(),
                                        _vm.isOriginalDatafileUploaded
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-3 text-info pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeOnlyDataset()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-close-circle-outline mr-1",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "uploadProcessStep1.form.fileSelector.removeLabel"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-popover",
                                                rawName: "v-b-popover.hover",
                                                value:
                                                  _vm.propoverMethodDatafileFormat,
                                                expression:
                                                  "propoverMethodDatafileFormat",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 bx bxs-info-circle text-info",
                                            staticStyle: { cursor: "pointer" },
                                          }),
                                        ]),
                                      ]),
                                      !_vm.isOriginalDatafileUploaded
                                        ? _c(
                                            "div",
                                            { staticClass: "custom-file" },
                                            [
                                              _c("b-form-file", {
                                                ref: "originalDatafile",
                                                attrs: {
                                                  id: "originalDatafile",
                                                  placeholder: _vm.$t(
                                                    "uploadProcessStep1.form.fileSelector.placeholder"
                                                  ),
                                                  "drop-placeholder":
                                                    "Drop file here...",
                                                  accept: ".xlsx",
                                                },
                                                on: { change: _vm.uploadFile },
                                              }),
                                              _vm.displayOriginalFileRequiredError
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-small text-danger",
                                                    },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "uploadProcessStep1.form.fileSelector.requiredErrorMsg"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isOriginalDatafileUploaded
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  this.datafileKey.split(
                                                    "-T"
                                                  )[0] +
                                                    "." +
                                                    this.datafileKey.split(
                                                      "Z."
                                                    )[1]
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.fileUploadError
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger mt-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "uploadProcessStep1.form.fileSelector.uploadErrorMsg"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.displayDuplicateHeadersError
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger mt-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "uploadProcessStep1.form.fileSelector.duplicateErrorMsg"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "sheetName" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep1.form.sheetSelector.label"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          (!_vm.$v.selectedSheetName.$error &&
                                            _vm.isClickNextStep) ||
                                          _vm.isStep1Completed
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-check text-success bx-tada",
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isStep1Completed
                                        ? _c(
                                            "select",
                                            {
                                              staticClass: "form-control",
                                              staticStyle: {
                                                background: "#f5f5f5",
                                                "-webkit-appearance": "none",
                                                "-moz-appearance": "none",
                                              },
                                              attrs: {
                                                disabled: _vm.isStep1Completed,
                                              },
                                            },
                                            [
                                              _c("option", [
                                                _vm._v(
                                                  _vm._s(_vm.selectedSheetName)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c("multiselect", {
                                            class: {
                                              "is-invalid":
                                                _vm.$v.selectedSheetName.$error,
                                            },
                                            attrs: {
                                              disabled: _vm.isStep1Completed,
                                              options: _vm.sheetNames,
                                              multiple: false,
                                              "close-on-select": true,
                                              "clear-on-select": false,
                                              placeholder: _vm.$t(
                                                "uploadProcessStep1.form.sheetSelector.placeholder"
                                              ),
                                            },
                                            model: {
                                              value: _vm.selectedSheetName,
                                              callback: function ($$v) {
                                                _vm.selectedSheetName = $$v
                                              },
                                              expression: "selectedSheetName",
                                            },
                                          }),
                                      _vm.$v.selectedSheetName.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm.$v.selectedSheetName.$error &&
                                              !_vm.$v.selectedSheetName.required
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep1.form.sheetSelector.requiredErrorMsg"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-5" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "datafileHeaderRow" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep1.form.headerRow.label"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          (!_vm.$v.datafileHeaderRow.$error &&
                                            _vm.isClickNextStep) ||
                                          _vm.isStep1Completed
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-check text-success bx-tada",
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isStep1Completed
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.datafileHeaderRow,
                                                expression: "datafileHeaderRow",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.datafileHeaderRow.$error,
                                            },
                                            staticStyle: {
                                              background: "#f5f5f5",
                                            },
                                            attrs: {
                                              disabled: _vm.isStep1Completed,
                                              id: "datafileHeaderRow",
                                              type: "number",
                                              name: "datafileHeaderRow",
                                              placeholder: _vm.$t(
                                                "uploadProcessStep1.form.headerRow.placeholder"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.datafileHeaderRow,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.datafileHeaderRow =
                                                  $event.target.value.trim()
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.datafileHeaderRow,
                                                expression: "datafileHeaderRow",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.datafileHeaderRow.$error,
                                            },
                                            attrs: {
                                              id: "datafileHeaderRow",
                                              type: "number",
                                              name: "datafileHeaderRow",
                                              placeholder: _vm.$t(
                                                "uploadProcessStep1.form.headerRow.placeholder"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.datafileHeaderRow,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.datafileHeaderRow =
                                                  $event.target.value.trim()
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                      _vm.$v.datafileHeaderRow.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm.$v.datafileHeaderRow.$error &&
                                              !_vm.$v.datafileHeaderRow.required
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep1.form.headerRow.requiredErrorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-5" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "datafileDataRecordStartRow",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "uploadProcessStep1.form.startingRow.label"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          (!_vm.$v.datafileDataRecordStartRow
                                            .$error &&
                                            _vm.isClickNextStep) ||
                                          _vm.isStep1Completed
                                            ? _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bx-check text-success bx-tada",
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isStep1Completed
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.datafileDataRecordStartRow,
                                                expression:
                                                  "datafileDataRecordStartRow",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v
                                                  .datafileDataRecordStartRow
                                                  .$error,
                                            },
                                            staticStyle: {
                                              background: "#f5f5f5",
                                            },
                                            attrs: {
                                              disabled: _vm.isStep1Completed,
                                              id: "datafileDataRecordStartRow",
                                              type: "number",
                                              name: "datafileDataRecordStartRow",
                                              placeholder: _vm.$t(
                                                "uploadProcessStep1.form.startingRow.placeholder"
                                              ),
                                            },
                                            domProps: {
                                              value:
                                                _vm.datafileDataRecordStartRow,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.datafileDataRecordStartRow =
                                                  $event.target.value.trim()
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.datafileDataRecordStartRow,
                                                expression:
                                                  "datafileDataRecordStartRow",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v
                                                  .datafileDataRecordStartRow
                                                  .$error,
                                            },
                                            attrs: {
                                              id: "datafileDataRecordStartRow",
                                              type: "number",
                                              name: "datafileDataRecordStartRow",
                                              placeholder: _vm.$t(
                                                "uploadProcessStep1.form.startingRow.placeholder"
                                              ),
                                            },
                                            domProps: {
                                              value:
                                                _vm.datafileDataRecordStartRow,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.datafileDataRecordStartRow =
                                                  $event.target.value.trim()
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                      _vm.$v.datafileDataRecordStartRow.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm.$v.datafileDataRecordStartRow
                                                .$error &&
                                              !_vm.$v.datafileDataRecordStartRow
                                                .required
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "uploadProcessStep1.form.startingRow.requiredErrorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.$v.datafileDataRecordStartRow
                                                .$error &&
                                              !_vm.$v.datafileDataRecordStartRow
                                                .greterThanHeaderRow
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uploadProcessStep1.form.startingRow.shouldGraterThanHeaderRow"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "mb-5" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "uploadProcessStep1.missingValues.label"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.isClickNextStep
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass:
                                                  "bx bx-check text-success bx-tada",
                                              }),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("vue-tags-input", {
                                        staticStyle: { "max-width": "none" },
                                        attrs: {
                                          tags: _vm.missingValuesObjects,
                                          placeholder: _vm.$t(
                                            "uploadProcessStep1.missingValues.placeholder"
                                          ),
                                        },
                                        on: {
                                          "tags-changed": (newTags) =>
                                            _vm.tagsGetChange(newTags),
                                        },
                                        model: {
                                          value: _vm.vue_tag,
                                          callback: function ($$v) {
                                            _vm.vue_tag = $$v
                                          },
                                          expression: "vue_tag",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-3" }),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "fixed-bottom justify-content-end static-buttons",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body col-md-12 text-right" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickOnNextStep()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "uploadProcessStep1.form.nextStepButton"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("i", {
                                  staticClass: "mdi mdi-chevron-right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "row justify-content-center" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-center p-5" },
                    [
                      _c("img", {
                        staticStyle: { height: "60px" },
                        attrs: {
                          src: require("@/assets/images/information.png"),
                        },
                      }),
                      _c("h5", { staticClass: "mb-3 mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("liveImportStep1.contractPause.text1"))
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("liveImportStep1.contractPause.text2")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.clickOnContact()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bx bx-envelope font-size-18 mr-2",
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.$t("liveImportStep1.contractPause.button")
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
          ])
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }